import hideOnClickOutside from '../shared/click-outside'

document.addEventListener('turbolinks:load', () => {
  const trigger = document.querySelector('.js-trigger-popup-menu')
  if (trigger) {
    const popupMenu = document.querySelector(trigger.dataset.popupTarget)
    const mobileMenuContainer = document.querySelector(
      '.personal-menu-mobile__container'
    )

    trigger.addEventListener('click', () => {
      if (!popupMenu.classList.contains('show')) {
        hideOnClickOutside(trigger, { alsoHideElements: [popupMenu] })
        popupMenu.classList.add('show')
        mobileMenuContainer.classList.add('showing-popup-menu')
      } else {
        popupMenu.classList.remove('show')
        mobileMenuContainer.classList.remove('showing-popup-menu')
      }
    })

    const personalMenuContainerHeight = document.querySelector(
      '.personal-menu__container'
    ).offsetHeight

    const checkContainerOverflow = () => {
      const menu = document.querySelector('.personal-menu__container')
      const topOffset = 100

      if (topOffset + personalMenuContainerHeight > window.innerHeight) {
        menu.classList.add('scrollable', 'full-height')
      } else {
        menu.classList.remove('scrollable', 'full-height')
      }
    }

    window.addEventListener('resize', () => {
      debounce(checkContainerOverflow, 300)
    })

    checkContainerOverflow()
  }
})

let debounceTimer

const debounce = (callback, time) => {
  window.clearTimeout(debounceTimer)
  debounceTimer = window.setTimeout(callback, time)
}
