const getMobileOS = () => {
  const ua = navigator.userAgent
  if (/android/i.test(ua)) {
    return 'android'
  } else if (!window.MSStream && /iPad|iPhone|iPod/.test(ua)) {
    return 'ios'
  }
  return 'desktop'
}

window.mobileDevice = getMobileOS()
window.progressiveWebApp = window.matchMedia(
  '(display-mode: standalone)'
).matches

document.addEventListener('turbolinks:load', () => {
  if (window.progressiveWebApp) {
    document.querySelector('html')?.classList?.add('pwa', window.mobileDevice)
  } else {
    document
      .querySelector('html')
      ?.classList?.remove('pwa', window.mobileDevice)
  }
})
