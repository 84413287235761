// Initialize deferredPrompt for use later to show browser install prompt.
let deferredPrompt
let eventId

window.addEventListener('beforeinstallprompt', (e) => {
  // Prevent the mini-infobar from appearing on mobile
  e.preventDefault()
  if (document.getElementById('promote-android-install')) {
    // Stash the event so it can be triggered later.
    deferredPrompt = e

    const firstVisitComplete =
      JSON.parse(localStorage.getItem('firstVisitComplete')) || []

    // Update UI notify the user they can install the PWA
    if (
      window.mobileDevice == 'android' &&
      firstVisitComplete.includes(eventId)
    ) {
      showAndroidInstallPromotion(deferredPrompt)
    }
  }
})

window.addEventListener('turbolinks:load', () => {
  let promoteElements = document.querySelectorAll('.promote-pwa__container')
  eventId = promoteElements[0]?.dataset?.currentEvent

  const firstVisitComplete =
    JSON.parse(localStorage.getItem('firstVisitComplete')) || []

  if (firstVisitComplete.includes(eventId)) {
    if (document.getElementById('promote-ios-install')) {
      if (window.mobileDevice == 'ios' && !window.progressiveWebApp) {
        showIosInstallPromotion()
      }
    }

    if (
      document.getElementById('promote-ios-install') ||
      document.getElementById('promote-android-install')
    ) {
      document
        .querySelectorAll('.js-promote-pwa-close')
        .forEach((closeElement) => {
          closeElement.addEventListener('click', () => {
            hideInstallPromotion()
          })
        })
    }
  } else {
    recordFirstVisit()
  }
})

const showAndroidInstallPromotion = (deferredPrompt) => {
  if (
    JSON.parse(localStorage.getItem('hideInstallPromotion'))?.includes(eventId)
  ) {
    return
  }

  document
    .getElementById('promote-android-install')
    .addEventListener('click', async () => {
      // Hide the app provided install promotion
      hideInstallPromotion()
      // Show the install prompt
      deferredPrompt.prompt()
      deferredPrompt = null
    })

  const promoteElement = document.getElementById('promote-android-install')
  if (promoteElement) {
    promoteElement.classList.remove('hidden')
    setTimeout(() => {
      promoteElement.classList.add('showing')
    }, 5000)
  }
}

const showIosInstallPromotion = () => {
  if (
    JSON.parse(localStorage.getItem('hideInstallPromotion'))?.includes(eventId)
  ) {
    return
  }

  const promoteElement = document.getElementById('promote-ios-install')
  if (promoteElement) {
    promoteElement.classList.remove('hidden')
    setTimeout(() => {
      promoteElement.classList.add('showing')
    }, 5000)
  }
}

const hideInstallPromotion = () => {
  let hideInstallPromotion =
    JSON.parse(localStorage.getItem('hideInstallPromotion')) || []
  hideInstallPromotion.push(eventId)
  localStorage.setItem(
    'hideInstallPromotion',
    JSON.stringify(hideInstallPromotion)
  )
  document.getElementById('promote-android-install')?.classList?.add('hidden')
  document.getElementById('promote-ios-install')?.classList?.add('hidden')
}

const recordFirstVisit = () => {
  let visits = JSON.parse(localStorage.getItem('firstVisitComplete')) || []

  if (!visits.includes(eventId)) {
    visits.push(eventId)
    localStorage.setItem('firstVisitComplete', JSON.stringify(visits))
  }
}
