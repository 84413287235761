export default function hideOnClickOutside(
  clickOutsideContainer,
  options = {}
) {
  const outsideClickListener = (event) => {
    if (
      !clickOutsideContainer.contains(event.target) &&
      isVisible(clickOutsideContainer)
    ) {
      removeClickListener(options['onCloseFunction'])
      clickOutsideContainer.classList.remove('show')
      const elements = options['alsoHideElements']
      if (elements) {
        elements.forEach((element) => {
          element.classList.remove('show')
        })
      }
    }
  }

  const removeClickListener = (onCloseFunction) => {
    document.removeEventListener('click', outsideClickListener, {
      capture: true,
    })

    if (onCloseFunction) {
      onCloseFunction()
    }
  }

  document.addEventListener('click', outsideClickListener, { capture: true })
}

const isVisible = (elem) =>
  !!elem &&
  !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length)
